.live-chat-container {
    background-color: #323338;
    height: 3rem;
    color: white;
    border-bottom: solid 2px rgba(0, 0, 0, .25);
    display: flex;
    align-items: center;
}

.inner-live-chat-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0px 20px;
}

.inbox-icon {
    font-size: 25px;
    margin-right: 15px;
}
.question-icon {
    font-size: 25px;
    cursor: pointer;
}

.dev-dropdown {
    display: flex;
    align-items: center;
}

.dev-icons {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin: 5px;
}

.dev-dropdown-content {
    display: flex;
    gap: 10px;
    margin: 10px 5px;
    font-size: 12px;
    align-items: center;
}

.dev-contents {
    display: flex;
    gap: 5px;

}

.close-dev-icon {
    font-size: 18px;
    cursor: pointer;
}

.dev-links {
    cursor: pointer;
    margin: 0px 2px;
    font-size: 14px;
    display: flex;
    gap: 5px;

}

.dev-links > a {
    color: white;
    display: flex;
    align-items: center;
}
