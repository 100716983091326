.main-chat-and-input-container {
  display: grid;
  grid-template-rows: repeat(20, 1fr);
  height: 100vh;
  margin-top: 5px;
  position: relative;
}

.chat-container {
  margin-bottom: 5px;
  overflow-y: scroll;
  grid-row-start: 1;
  grid-row-end: 19;
  padding-bottom: 25px;
  margin-right: 3px;
  display: flex;
  flex-direction: column-reverse;
  overflow-x: hidden;
}

/* width */
.chat-container::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
.chat-container::-webkit-scrollbar-track {
  background: #2d2d2d;
  border-radius: 15px;
}

/* Handle */
.chat-container::-webkit-scrollbar-thumb {
  border-radius: 15px;
  background: #222222;
}

/* Handle on hover
::-webkit-scrollbar-thumb:hover {
  background: #555;
} */

/* css for the image of each chatbox */
.chatbox-logo-wrapper,
.chatbox-image {
  width: 42px;
  height: 42px;
  background-color: rgb(218, 46, 46);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  position: absolute;
}

.chatbox-logo {
  width: 25px;
  height: 25px;
}

.chat-box-name-date-message-wrapper {
  margin-left: 59px;
}

.chat-box-name-date-wrapper {
  margin-bottom: 6px;
}

.chat-box-name {
}

.chat-box-date {
  color: #949ba4;
  margin-left: 10px;
  font-size: 10px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bolder;
}

.chat-box-message {
  max-width: calc(100vw - 700px);
  white-space: normal;
  overflow-wrap: break-word;
  margin: 0px;
  color: #dbdee1;
  font-weight: 200;
}

/* styling for if a user recently sent a message */

.chat-box-message-only {
  max-width: calc(100vw - 700px);
  white-space: normal;
  overflow-wrap: break-word;
  /* margin-left: 60px; */
  /* margin: 5px 17px 0px 75px; */
  margin: 0px;
  color: #dbdee1;
  font-weight: 200;
}
