.instructions-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: white;
    position: relative;
}

.instructions-container>h1 {
    font-size: 50px;
    margin: 0px;
}

.instructions-container>h3 {
    font-size: 24px;
    color: #b5bac1;
}

.discover-server-instruction {
    width: 350px;
    border-radius: 5px;
    background-color: #232428;
    padding: 20px;
    margin: 10px 0px;
    cursor: pointer;
}

.discover-server-instruction:hover {
    background-color: #383C41;
    box-shadow: -1px 1px 5px #232428b7;
}

.instruction-button {
    display: flex;
    align-items: center;
}

.instruction-button > div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.instruction-button > div > p {
    color: #f2f3f5;
    font-weight: bold;
    font-size: 14px;
}

.instructions-icon {
    height: 40px;
    width: 40px;
    margin-right: 20px;
}


.instruction-menu-wrapper {
    position: absolute;
    top: 9px;
    right: 20px;
    /* border: 2px solid #f2f3f5; */
}

.instruction-cog {
    display: flex;
    color: #ADADAD;
    font-size: 27px;
    padding: 4px;
    margin-left: 0px;
}

.instruction-cog:hover {
    color: #c8c8c8;
    background-color: #3b3b3b;
    border-radius: 5px;
    cursor: pointer;
}