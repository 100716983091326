.change-avatar-server {
    width: 125px;
    height: 125px;
    border-radius: 50%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: 0;
    font-weight: bold;
}

.change-avatar-server:hover {
    background-color: #1f212476;
    opacity: 100%;
    color: #f2f3f5;
}

.change-banner-server {
    width: 300px;
    height: 124px;
    border-radius: 5px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: 0;
    margin-left: 60px;
    font-weight: bold;
}

.change-banner-server:hover {
    background-color: #32353a76;
    opacity: 100%;
    color: #f2f3f5;
}

.change-avatar-input {
    position: absolute;
    z-index: 1;
    width: 125px;
    height: 125px;
    border-radius: 50%;
    cursor: pointer;
    opacity: 0%;
}

.change-banner-input {
    position: absolute;
    z-index: 1;
    width: 300px;
    height: 124.2px;
    border-radius: 50%;
    cursor: pointer;
    opacity: 0%;
}

.server-image-icon {
    position: absolute;
    height: 20px;
    width: 20px;
    margin-left: 3px;
}

.server-image-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #E2E5E8;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    box-shadow: -1px 1px 5px #4F545C;
    color: #4F545C;
    top: 75px;
    left: 350px;
    z-index: 10;
    position: absolute;
}

.server-banner-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #E2E5E8;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    box-shadow: -1px 1px 5px #4F545C;
    color: #4F545C;
    top: 75px;
    right: 80px;
    z-index: 10;
    position: absolute;
}


.upload-banner-button {
    position: absolute;
    top: 210px;
    right: 170px;
    border: none;
    background-color: transparent;
    color: var(--color-ADADAD);
    cursor: pointer;
    font-weight: bold;
    cursor: default;
}

.upload-avatar-button {
    position: absolute;
    top: 212px;
    border: none;
    background-color: transparent;
    color: var(--color-ADADAD);
    cursor: pointer;
    font-weight: bold;
    cursor: default;
}
