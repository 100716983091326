.leave-server-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.inner-leave-server {
    background-color: #303338;
    border-radius: 4px;
    box-shadow: 1px 1px 10px#1f2124;
    display: flex;
    width: 440px;
    height: 200px;
    flex-direction: column;
}

.leave-server-cancel-submit {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    background-color: #2A2D31;
    border-radius: 0px 0px 4px 4px;
}

.cancel-leave-server {
    color: white;
    font-size: 14px;
    margin-right: 30px;
}

.cancel-leave-server:hover {
    cursor: pointer;
    text-decoration: underline;
}

.leave-server-button {
    border: none;
    outline: none;
    font-size: 13px;
    background-color: #DA363C;
    color: white;
    border-radius: 4px;
    width: 110px;
    height: 40px;
}

.leave-server-button:hover {
    background-color: #a2272b;
    color: rgba(255, 255, 255, 0.838);
    cursor: pointer;
}
