.message-container {
  position: relative;
  margin-left: 7px;
}

.message-container:hover {
  background-color: #2e3035;
  margin-right: 7px;
  border-radius: 3px;
}
#message-container-active {
  background-color: #2e3035;
  margin-right: 7px;
  margin-left: 7px;
  border-radius: 3px;
}

.message-settings-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-color: #313338;
  border: 1px solid #2a2b30;
  border-radius: 6px;
  /* box-shadow: 10px 5px 5px #ffffff; */
  width: 96px;
  height: 32px;
  position: absolute;
  right: 15px;
  top: -18px;
  z-index: 999;
}

.message-emoji,
.message-edit,
.message-more {
  width: 100%;
  font-size: 17px;
}

.message-icon-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.message-icon-container:hover {
  background-color: #3a3c41;
  cursor: pointer;
}

.emoji {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.more {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.message-wrapper {
  margin: 25px 17px 0px 17px;
  padding-top: 5px;
  padding-bottom: 5px;
  position: relative;
  width: 98%;
}


.message-only-wrapper {
  margin: 0px 17px 0px 75px;
  padding: 5px 0px;
}

.message-edit-wrapper {
  margin-left: 0px;
  padding: 0px 0px;
  display: flex;
  align-items: center;
  max-width: 95%;
  min-width: 5%;
}

.message-edit-form {
  margin-left: 0px;
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  width: 100%;
}

.message-edit-input {
  width: 95%;
  /* max-width: 90%; */
  /* line-height: normal; */
  padding: 13px 20px 13px 20px;
  font-size: 15px;
  color: #DBDEE1;
  background-color: #373A3F;
  border-style: none;
  border-radius: 8px;
  white-space: normal;
  overflow-wrap: break-word;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.message-edit-input:focus {
  outline: none;
}

.message-edit-chat-emoji-icon {
  position: absolute;
  color: #c1c9d2;
  font-size: 25px;
  cursor: pointer;
  top: 35px;
  right: 50px;
}

.message-edit-cancel-save {
  font-size: 11px;
  margin: 0px;
  margin-top: 8px;
}

.message-edit-cancel, 
.message-edit-save {
  color: #00A8FC;
  cursor: pointer;
}

.message-edit-cancel:hover, 
.message-edit-save:hover {
  text-decoration: underline;
}

.message-edit-save {
	background: none;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
}