.landing-main-body-inner-container {
    display: grid;
    grid-template-rows: 0.5fr 0.5fr 0.5fr 0.5fr 1fr;
}

/* @media (max-width: 930px) {
    #w1-p1 {
        font-size: 10px;
    }
    
    #w1-p2 {
        font-size: 1.188rem;
    }

    #w2-body > h2 {
        font-size: 1rem;
    }
    
    #w2-body > p {
        font-size: 1.25rem;
    }
} */

/* ----------------section 1 wrapper----------------------------- */
.landing-body-wrapper-1 {
    background-color: rgb(79,27,245);
    color: white;
    display: flex;
    justify-content: center;
    background-image: url("../../../images/banner.webp");
    background-size: 100% 100%;
}


.wrap-1-body {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(3, 0.5fr);
    grid-column-gap: 1.25rem;
    height: 15.725rem;
    padding: 7.5rem 5rem;
    max-width: 78.75rem;
}

#w1-p1 {
    grid-column-start: 2;
    grid-column-end: 12;
    font-size: 4.375rem;
    display: flex;
    justify-content: center;
    margin: 0.625rem 0px;
}

#w1-p2 {
    grid-column-start: 2;
    grid-column-end: 12;
    text-align: center;
    font-size: 1.188rem;
    height: fit-content;
    line-height: 2;
    padding: 0px 1.875rem;
    margin: 0.625rem 0px
}

.w1-buttons-wrapper {
    grid-column-start: 3;
    grid-column-end: 11;
    display: flex;
    justify-content: center;
}

.w1-buttons {
    width: 18.75rem;
    height: 2.813rem;
    border-radius: 1.875rem;
    background-color: white;
    border-style: none;
    font-size: 1.25rem;
    margin: 0.625rem;
    /* cursor: pointer; */
}

/* .w1-buttons:hover {
    box-shadow: 0px 0.1rem 0.7rem 0.063rem rgba(0, 0, 0, 0.5);
} */


/* ----------------section 2 wrapper----------------------------- */
.landing-body-wrapper-2 {
    display: flex;
    justify-content: center;
}

.wrap-2-body {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(2, 1fr);
    height: 25.25rem;
    padding: 7.5rem 5rem;
    grid-column-gap: 1.25rem;
    max-width: 78.75rem;
}

#w2-img-wrapper {
    grid-column-start: 1;
    grid-column-end: 8;
    grid-row-start: 1;
    grid-row-end: 3;
    max-height: 85%;
    margin-top: 40px;
}

#w2-img-wrapper > img{
    width: 100%;
    height: 100%;
    background-color: red;
}

#w2-body {
    grid-column-start: 8;
    grid-column-end: 13;
    grid-row-start: 1;
    grid-row-end: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#w2-body > h2 {
    font-size: 3rem;
    margin: 0.313rem;
}

#w2-body > p {
    font-size: 1.25rem;
    line-height: 1.5;
    margin: 1.25rem 0.313rem;
}

/* ----------------section 3 wrapper----------------------------- */
.landing-body-wrapper-3 {
    display: flex;
    justify-content: center;
    background-color: rgb(248, 248, 246);
}

.wrap-3-body {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(2, 1fr);
    height: 31.25rem;
    padding: 7.5rem 5rem;
    grid-column-gap: 1.25rem;
    max-width: 78.75rem;
}


#w3-img-wrapper {
    grid-column-start: 7;
    grid-column-end: 13;
    grid-row-start: 1;
    grid-row-end: 3;
    max-height: 90%;
}

#w3-img-wrapper > img{
    width: 100%;
    height: 100%;
    background-color: red;
}

#w3-body {
    grid-column-start: 1;
    grid-column-end: 6;
    grid-row-start: 1;
    grid-row-end: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 3.125rem;
}
#w3-body > h2 {
    font-size: 3.125rem;
    margin: 0.313rem;
}

#w3-body > p {
    font-size: 1.25rem;
    line-height: 1.5;
    margin: 1.25rem 0.313rem;
}

/* ----------------section 4 wrapper----------------------------- */
.landing-body-wrapper-4 {
    display: flex;
    justify-content: center;
}

.wrap-4-body {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(2, 1fr);
    height: 31.25rem;
    padding: 7.5rem 5rem;
    grid-column-gap: 1.25rem;
    max-width: 78.75rem;
}


#w4-img-wrapper {
    grid-column-start: 1;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 3;
    max-height: 90%;
}

#w4-img-wrapper > img{
    width: 100%;
    height: 100%;
    background-color: red;
}

#w4-body {
    grid-column-start: 8;
    grid-column-end: 13;
    grid-row-start: 1;
    grid-row-end: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
#w4-body > h2 {
    font-size: 3.125rem;
    margin: 0.313rem;
}

#w4-body > p {
    font-size: 1.25rem;
    line-height: 1.5;
    margin: 1.25rem 0.313rem;
}

/* ----------------section 5 wrapper----------------------------- */
.landing-body-wrapper-5 {
    background-color: rgb(248, 248, 246);
    display: flex;
    justify-content: center;
}
.wrap-5-body {
    background-color: rgb(248, 248, 246);
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 1fr 2fr;
    height: 43.75rem;
    padding: 7.5rem 5rem;
    grid-column-gap: 1.25rem;
    max-width: 78.75rem;
}

#w5-body {
    grid-column-start: 2;
    grid-column-end: 12;
    grid-row-start: 1;
    grid-row-end: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#w5-body > h2 {
    font-size: 3.125rem;
    margin: 0.313rem;
    text-align: center;
}

#w5-body > p {
    font-size: 1.25rem;
    line-height: 1.75;
    margin: 0.938rem 0.313rem;
    text-align: center;
}

#w5-img-wrapper {
    grid-column-start: 1;
    grid-column-end: 13;
    grid-row-start: 2;
    grid-row-end: 3;
    max-height: 45%;
}

#w5-img-wrapper > img {
    width: 100%;
    height: 100%;
    background-color: red;
    margin-top: 3.125rem;
}


/* ----------------section 6 wrapper----------------------------- */
.landing-body-wrapper-6 {
    display: flex;
    justify-content: center;
    background-color: rgb(248, 248, 246);
}

.wrap-6-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 7.5rem;
    padding-top: 3.125rem;
}

.landing-body-wrapper-6 > h2 {
    font-size: 1.875rem;
}

.w6-button {
    width: 23.125rem;
    height: 2.813rem;
    border-radius: 1.875rem;
    background-color: #5865FC;
    color: rgb(248, 248, 246);
    border-style: none;
    font-size: 1.25rem;
    cursor: pointer;
}

.w6-button:hover{
    box-shadow: 0px 0.1rem 0.7rem 0.063rem rgba(0, 0, 0, 0.5);
}

.return-up {
    width: 3.75rem;
    height: 3.75rem;
    background-color: rgb(243, 243, 243);
    border-radius: 1.125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: fixed;
    right: -.012rem;
    bottom: 7.5rem;
    font-size: 2.688rem;
    padding-bottom: 0.313rem;
    color: black;
}
.return-up:hover {
    box-shadow: 0px 0.1rem 0.7rem 0.063rem rgba(0, 0, 0, 0.5);
}

#landing-container {
    position: relative;
}
