.nav-outer-container {
    background-color: rgb(79, 27, 245);
    /* display: flex;
    justify-content: space-between; */
    border-bottom: 1px solid rgb(126, 126, 126);
}


.nav-inner-container {
    /* display: grid;
    grid-template-columns: 1fr 10fr 1fr;
    color: white;
    max-width: 78.75rem; */
    padding: 0.625rem 2.5rem;
    display: flex;
    justify-content: space-between;
}

.logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.125rem;
}

.logo-wrapper>a>img {
    height: 2.5rem;
    padding-right: 0.625rem;
}

.logo-wrapper>a {
    text-decoration: none;
    color: white;
    display: flex;
    align-items: center;
}

.nav-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 5.375rem;
}

.nav-box>p {
    margin: 0px 0.375rem;
}

/* .nav-box > a{
    text-decoration: none;
    color: white;
} */

.nav-login-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.landing-login-button {
    width: max-content;
    padding: 0px 20px;
    height: 2.313rem;
    border-radius: 1.875rem;
    background-color: white;
    border-style: none;
    font-size: 0.938;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
}

.landing-login-button:hover {
    box-shadow: 0px 0.1rem 0.7rem 0.063rem rgba(0, 0, 0, 0.5);
}
