.password-container {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: #4764ce;
    background-image: url("../../../images/discord-signup-login.png");
}

.password-container-2 {
    box-shadow:1px 1px 10px#1f2124;
    padding: 0px 10px;
    display: flex;
    flex-direction: row;
    background-color: #35393e;
    width: 450px;
    border-radius: 5px;
    margin: 10px;
}

.password-container-3 {
    padding: 20px;
    width: 100%;
    text-align: center;
    margin-bottom: 8px;
    overflow: visible;
    height: 450px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.password-container-4 {
    display: flex;
    justify-content: center;
    width: inherit;
    max-width: 960px;
}

.password-container-5 {
    width: 100%;
    max-width: 400px;
}

.password-container-6 {
    display: flex;
    width: 100%;
    flex-direction: column;
}

/*  ---------------------------------------- Form Section ---------------------------------------------*/

.form-password-header {
    font-size: 24px;
    color: white;
    margin-bottom: 10px;
}

.form-login-message {
    color: #99aab5;
    font-size: 15px;
    margin-top: 5px;
}
