.server-profile-inner-2 {
    width: 100%;
    overflow: visible;
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-left: 40px;
}

.server-profile-save {
    height: 100%;
}

.server-profile-form {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-left: -11px;
}



.server-profile-form-main {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 0px;
    align-items: center;
    margin: 0px 15px 0px 15px;
}

.server-profile-save-delete-button  {
    display: flex;
    height: 55%;
    align-items: flex-end;
    width: 100%;
    justify-content: end;
    margin-right: 84px;
    margin-bottom: 33px;
}

.leave-server {
    display: flex;
    align-items: center;
}

.server-setting-header-container {
    display: flex;
    width: 100%;
}

.server-profile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    color: white;
    margin-bottom: 10px;
    margin-top: 0px;
    text-align: left;
    width: 100%;
}

.exit-server-profile {
    color: #686b6f;
    font-size: 40px;
    cursor: pointer;

}
