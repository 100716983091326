.create-server-container {
    width: 450px;
    height: 380px;
    background-color: rgb(247, 246, 246);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* majority of the wrap which includes 
everything except for bottom div with button */
.c-server-top-wrap {
    height: 100%;
}

.c-server-header,
.c-server-description {
    text-align: center;
}
.c-server-header {
    font-size: 23px;
    margin-top: 30px;
    color: black;
    font-family:Arial, Helvetica, sans-serif;
    font-weight: bolder;
}

.c-server-description {
    font-size: 15px;
    margin: 0px 30px;
    color: rgb(123, 118, 118);
    font-family:Arial, Helvetica, sans-serif
}

.c-server-image-outer-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px;
    position: relative;
}

.c-server-image-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px dashed black;
    border-radius: 50px;
    width: 10%;
    padding: 20px;
    cursor: pointer;
    position: relative;
    /* z-index: 1; */
    right: 0px;
}

.c-server-image-input {
    background-color:  rgb(223, 222, 222);
    height: 35px;
    margin-top: 10px;
    border-style: none;
    border-radius: 5px;
    padding-left: 13px;
    font-size: 15px;
    margin-bottom: 20px;
    position: absolute;
    width: 90px;
    height: 90px;
    border-radius: 50px;
    opacity: 0;
    cursor: pointer;
}

.c-server-camera {
    font-size: 25px;
}

.c-server-thumb-icon {
    width: 25px;
    height: 25px;
    color: rgb(247,246,246);
    /* margin-left: 5px; */
    /* padding: 4px; */
}

.c-server-upload-text {
    margin: 0px;
    font-weight: bolder;
    font-size: 11px;
}

.c-server-upload-text-confirm {
    margin: 0px;
    font-weight: bolder;
    font-size: 11px;
    color: rgb(247,246,246);
}

.c-cerver-plus {
    position: absolute;
    background-color: #5865FC;
    top: -18px;
    right: 0px;
    width: 23px;
    height: 23px;
    border-radius: 25px;
    text-align: center;
    cursor: pointer;
}

.c-cerver-plus > span {
    font-size: 20px;
    font-weight: lighter;
    font-family:Arial, Helvetica, sans-serif;
    color: rgb(247, 246, 246);
    cursor: pointer;
}


.c-server-image-label {
    display: flex;
    flex-direction: column;
    padding: 0px 18px;
    font-size: 11px;
    font-weight: bolder;
    color: black;
    font-family:Arial, Helvetica, sans-serif;
    margin-top: 25px;
}


.c-server-image-input:focus {
    outline: none;
}


.c-server-name-label {
    display: flex;
    flex-direction: column;
    padding: 0px 18px;
    font-size: 11px;
    font-weight: bolder;
    color: black;
    font-family:Arial, Helvetica, sans-serif;
}


.c-server-name-input {
    background-color:  rgb(223, 222, 222);
    height: 35px;
    margin-top: 10px;
    border-style: none;
    border-radius: 5px;
    padding-left: 13px;
    font-size: 15px;
}


.c-server-name-input:focus {
    outline: none;
}


.c-server-agreement {
    color: rgb(123, 118, 118);
    font-size: 12px;
    margin-left: 17px;
    margin-top: 10px;
}

.c-server-guidelines {
    color: rgb(43, 98, 226);
    font-family:Arial, Helvetica, sans-serif;
    font-weight: bolder;
    font-size: 12px;
}





/* bottom wrap where back and create button is */
.c-server-bottom-wrap {
    background-color: rgb(236, 236, 236);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 20%;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.c-server-back {
    margin-left: 15px;
    cursor: pointer;
    padding: 10px;
    font-size: 14px;
}


.c-server-create-button {
    margin-right: 20px;
    width: 20%;
    height: 55%;
    background-color: #5865FC;
    color: rgb(247, 246, 246);
    border-style: none;
    border-radius: 3px;
    font-size: 14px;
    cursor: pointer;
}

#c-server-button-disabled {
    margin-right: 20px;
    width: 20%;
    height: 55%;
    background-color: #a8a8ab;
    color: rgb(247, 246, 246);
    border-style: none;
    border-radius: 3px;
    font-size: 14px;
    cursor: default;
}

.c-server-create-button:hover {
    background-color: #4752C4;
}