.server-list-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* each server card */
.image-container {
  background-color: rgb(48, 48, 48);
  border-radius: 40px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 11px;
  text-align: center;
  max-width: 50px;
  color: rgb(177, 177, 177);
  font-weight: bolder;
  font-size: 20px;
  transition: border-radius 0.5s, background-color 0.3s;
}


.servers {
  background-color: rgb(48, 48, 48);
  border-radius: 40px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 11px;
  text-align: center;
  max-width: 50px;
  color: rgb(177, 177, 177);
  font-weight: bolder;
  font-size: 20px;
  transition: border-radius 0.5s, background-color 0.3s;
}

.servers:hover {
  border-radius: 15px;
  background-color: #4752c4;
  color: #dbdee1;
}

/* #active-server {
  border-radius: 15px;
  background-color: #4752c4;
  color: #DBDEE1;
} */

.active-server {
  color: #dbdee1;
  border-radius: 15px;
  background-color: #4752c4;
}

/* .server-list-wrapper:hover {
  border-left: 0.1px solid white;
} */

.server-initials {
  pointer-events: none;
  background-color: rgb(48, 48, 48, 0);
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 11px;
  text-align: center;
  max-width: 50px;
  font-weight: bold;
  font-size: 20px;
  transition: border-radius 1s, background-color 1s;
}

.servers-initals:hover {
  border-radius: 15px;
  background-color: #4752c4;
  color: #dbdee1;
}

/* -------------------------------------------- */

.plus {
  background-color: rgb(48, 48, 48);
  border-radius: 40px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 11px;
  text-align: center;
  max-width: 50px;
  color: green;
  font-size: 40px;
  transition: border-radius 0.5s;
  transition: background-color 0.5;
  cursor: pointer;
}

.compass {
  background-color: rgb(48, 48, 48);
  border-radius: 40px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 11px;
  text-align: center;
  max-width: 50px;
  color: green;
  cursor: pointer;
  transition: border-radius 0.5s;
  transition: background-color 0.5;
}

.compass:hover {
  border-radius: 15px;
  /* color: rgb(177, 177, 177); */
  color: #dbdee1;
  background-color: green;
}

.compass-icon {
  width: 25px;
  height: 25px;
}

.plus:hover {
  border-radius: 15px;
  /* color: rgb(177, 177, 177); */
  color: #dbdee1;
  background-color: green;
}

#active-plus {
  border-radius: 15px;
  background-color: green;
  /* color: rgb(177, 177, 177); */
  color: #dbdee1;
  transition: border-radius 5s, background-color 10s;
}

/* ----------------------------------------------------- */

.server-logo {
  width: 28px;
  height: 28px;
}

/* image of the server card */
.servers-img {
  width: 100%;
  height: 100%;
  border-radius: 40px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 11px; */
  text-align: center;
  max-width: 50px;
  transition: border-radius 0.3s;
  /* transition: border-radius 2s, background-color 1s; */
}

.servers-img:hover {
  border-radius: 15px;
}

.active-server-img {
  color: #dbdee1;
  border-radius: 15px;
}

.server-top-layer > a {
  text-decoration: none;
}

/* just the white line between all servers and DM */
.border-between-layer {
  border: 1px solid rgb(199, 196, 196);
  width: 40px;
}

/*





efgweiyufbieu bgeu eir bgij;rgeb;brejbg iub  re;bgerug



*/
.server-bottom-layer {
  padding: 0px;
  margin-top: 5px;
  height: calc(100vh - 100px);
  max-height: 100vh;
  overflow: hidden scroll;
  z-index: 10;
  list-style: none;
  /* width: 21vw; */
  /* width: 100px; */
  /* padding-left: 227px; */
}

.server-bottom-layer::-webkit-scrollbar {
  display: none;
}

.server-bottom-layer > li {
  list-style-type: none;
}

.server-bottom-layer > li > a {
  text-decoration: none;
}

.server-hover {
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
}

.server-line {
  background-color: white;
  height: 20px;
  width: 5px;
  position: absolute;
  bottom: 15px;
  left: -10px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  opacity: 0;
  transition: none;
}

.server-hover:hover .server-line {
  opacity: 1;
  width: 4px;
  transition: opacity 0.3s ease-in, width 1.3s ease-in;
}

.server-line.hidden {
  opacity: 0;
  width: 4px;
}

.server-line.visible {
  opacity: 1;
  width: 4px;
}
