.footer-container {
    background-color: #23272A;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
}

.top-box {
    display: flex;
    height: 21.125rem;
    padding-top: 25px;
    border-bottom: 0.063rem solid rgb(70, 38, 228);
    width: 100%;
    justify-content: space-evenly;

}

.footer-logo-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.125rem;
    margin-left: 100px;
    width: 100%;
}

.footer-logo-wrapper > a > img {
    border: 0.313rem solid white;
    width:92%;
    padding-right: 0.225rem;
    padding-left: 0.188rem;
    margin-left: 0.625rem;
    margin-right: 0.188rem;
    margin-top: 0.313rem;
}



.bottom-box {
    display: flex;
    align-items: center;
    margin: 0.625rem 2.813rem;
    width: 100%;
}

.footer-button {
    width: 12.5rem;
    height: 2.313rem;
    border-radius: 1.875rem;
    background-color: #5865FC;
    border-style: none;
    font-size: 0.938rem;
    color: white;
}

.developer-image {
    width: 200px;
    height: 200px;
    border-radius: 5px;
}

.meet-developers {
    text-align: center;
    font-size: 32px;
    color: #f2f3f5;
    margin-bottom: 20px;
}

.inner-footer {
    width: 100%;

}

.logo-wrapper-footer {
    display: flex;
    align-items: center;
    font-size: 1.125rem;
}

.logo-wrapper-footer > a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-left: 45px;
}

.logo-wrapper-footer > a >img {
    height: 2.5rem;
    padding-right: 0.625rem;
}

.logo-wrapper-footer > a > h2 {
    color: white;
}

.developer-wrapper > p {
    text-align: center;
    color: #f2f3f5;
    font-size: 24px;
    margin-top: 0px;
    margin-bottom: 5px;
}

.developer-links {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.developer-links > a {
    display: flex;
    align-items: center;
    color: #dbdee1;
}

.developer-links > a > svg{
    margin-right: 5px;
}

.github > svg {
    color: white;
    height: 20px;
    width: 20px;

}

.github:hover {
    text-decoration: underline;
}

.linkedin >svg {
    color: #0177B5;
    background-color: white;
    border-radius: 2px;
    margin-top: 1px;
    height: 20px;
    width: 20px;
}

.linkedin:hover {
    text-decoration: underline;
}
