.discover-server {
  background-color: #303338;
  width: 1000px;
  height: 612px;
  border-radius: 5px;
}

/* ------------- DISCOVER COMMUNITIES HEADER ------------- */

.discover-communities-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #7a73ff;
  border-radius: 5px 5px 0px 0px;
}

.discover-communities {
  font-size: 26px;
  margin-bottom: 0px;
  color: white;
}

.discover-description {
  font-size: 14px;
  color: white;
}

/* server list */

.discover-server-list-wrapper {
  padding: 0px 50px 0px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  overflow: hidden scroll;
  overflow-y: hidden scroll;
  height: 508px;
}

/* width */
.discover-server-list-wrapper::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.discover-server-list-wrapper::-webkit-scrollbar-track {
  background: #2d2d2d;
  border-radius: 15px;
}

/* Handle */
.discover-server-list-wrapper::-webkit-scrollbar-thumb {
  border-radius: 15px;
  background: #141414;
}

.discover-server-list {
  list-style: none;
  margin: 20px 20px;
  background-color: #232428;
  border-radius: 5px;
  width: 232px;
  height: 205px;
  position: relative;
  cursor: pointer;
  transition: box-shadow 0.5s;
}

.discover-server-list:hover {
  box-shadow: 0px 0px 10px #616471;
}

.server-banner {
  height: 75px;
  background-color: white;
  border-radius: 5px 5px 0px 0px;
  width: 100%;
  position: relative;
  margin-bottom: 35px;
}

.discovery-initial-server-name {
  position: absolute;
  background-color: antiquewhite;
  border-radius: 15px;
  margin-left: 15px;
  top: 62px;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  border: 3px solid #232428;
}

.server-discovery-content {
  height: 80px;
  margin: 15px;
}

.server-name-discovery {
  color: #f2f3f5;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.server-members-discovery-wrapper {
  display: flex;
  align-items: flex-end;
  height: 60px;
}

.member-tiny-circle {
  height: 6px;
  width: 6px;
  background-color: #b5bac0;
  border-radius: 50%;
  margin-bottom: 3px;
  margin-right: 5px;
}

.server-members-discovery {
  color: #b5bac0;
  font-size: 12px;
}
