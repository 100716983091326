.loading-logo {
  width: 42px;
  height: 42px;
  background-color: rgb(63, 65, 70);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  position: absolute;
  top: -4px;
  left: -40px;
}

.loading-img {
  width: 42px;
  height: 42px;
}

.loading-message-info-container {
  display: flex;
  flex-direction: column;
  margin-left: 17px;
}

.loading-message {
  color: rgb(59, 61, 66);
  display: inline-block;
  background-color: rgb(59, 61, 66);
  border-radius: 8px;
  max-width: calc(100vw - 700px);
  margin: 0px;
  margin-top: 10px;
  margin-right: 5px;
}

.loading-name {
  display: inline-block;
  color: rgb(66, 69, 73);
  background-color: rgb(66, 69, 73);
  border-radius: 8px;
  max-width: calc(100vw - 700px);
}

.loading-wrapper {
  /* margin-left: 17px; */
}

.loading-message-wrapper {
  margin: 25px 17px 0px 59px;
  padding-top: 5px;
  padding-bottom: 5px;
  position: relative;
  width: 98%;
}

.chat-container.loading {
  /* overflow-y: hidden; */
  overflow-x: hidden;
}

.chat-container.loading::-webkit-scrollbar-thumb {
  background: #2d2d2d;
  border-radius: 15px;
  display: none;
}
