.user-main-container {
    width: 100%;
    height: 100%;
}

.user-inner-container {
    height: 480px;
    width: 850px;
    background-color: #313338;
    border-radius: 5px;
    padding-right: 30px;
    display: flex;
}

/* ------------------------------  NAV BAR ------------------------------  */

.account-nav-container {
    background-color: #2A2D30;
    width: 100%;
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 5px 0px 0px 5px;
}

.account-nav-user-setting {
    color: #929AA3;
    font-size: 14px;
    padding-left: 15px;
    font-weight: bold;
    text-transform: uppercase;
}

.my-account-nav {
    margin-top: 5px;
    padding: 5px 10px;
    font-size: 18px;
    color: white;
    margin: 10px 15px 15px 15px;
    border-radius: 4px;
    background-color: #3F4247;
}

.my-account-nav:hover {
    color: var(--color-ADADAD);
    cursor: pointer;
}

.account-logout {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 10px;
    font-size: 18px;
    color: var(--color-ADADAD);
    margin: 10px 15px 15px 15px;
    cursor: pointer;
}

.account-logout:hover {
    background-color: #3F4247;
    color: white;
    border-radius: 4px;
}


/* ------------------------------ HEADER ------------------------------------ */
.my-account-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.user-my-account {
    color: #F2F3EA;
    font-size: 20px;
}

.user-background-setting {
    background-color: bisque;
    border-radius: 4px 4px 0px 0px;
    height: 100px;
}

.account-exit-modal {
    font-size: 35px;
    position: absolute;
    right: 12px;
    top: 10px;
    color: #F2F3EA;
    cursor: pointer;
}

/* ------------------------------ USER DETAIL AND EDIT BUTTON ------------------------------ */

.user-edit-settings {
    height: 72px;
    background-color: #1E1F22;
}

.user-profile-picture-setting {
    position: absolute;
    height: 80px;
    width: 80px;
    background-color: darkgray;
    border-radius: 50%;
    border: 5px solid #1E1F22;
    top: 125px;
    left: 265px;
}

.user-name-edit-profile {
    margin-left: 120px;
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    padding-right: 20px;
}

.account-name-setting {
    margin: 0px;
    color: #F2F3EA;
}

.account-edit-button {
    border: none;
    height: 32px;
    width: 130px;
    border-radius: 3px;
    font-size: 13px;
    color: white;
    background-color: #5764F2;
    cursor: pointer;
}

.change-avatar {
    color: white;
    position: absolute;
    z-index: 1;
    border-radius: 50%;
    height: 80px;
    width: 80px;
    top: 130px;
    left: 270px;
    display: flex;
    align-items: center;
    text-align: center;
    opacity: 0;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
}

.change-avatar:hover {
    opacity: 100;
    background-color: #1f212476;
}

.account-save-button {
    border: none;
    height: 32px;
    width: 130px;
    border-radius: 3px;
    font-size: 13px;
    color: white;
    background-color: #248046;
    cursor: pointer;
}

.account-save-button-container {
    display: flex;
}

.account-cancel {
    margin: 0px;
    margin-top: 8px;
    color: #F2F3EA;
    font-size: 14px;
    margin-right: 20px;
    cursor: pointer;
}

.user-display-label {
    margin-bottom: 10px;
    color: #B5BAC0;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
}

.user-display-input-label {
    margin-bottom: 8px;
    color: #B5BAC0;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
}

/* ------------------------------ CHANGE AVATAR FORM PAGE ------------------------------ */

.change-avatar-container {
    width: 350px;
    height: 320px;
    background-color: #303338;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    flex-direction: column;
    padding-top: 8px;
}

.change-avatar-form {
    text-align: center;
}

.select-avatar-wrapper {
    border-radius: 4px;
    background-color:#232428;
    width: 220px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.change-avatar-photo-form {
    position: absolute;
    background-color: white;
    outline: none;
    border: none;
    cursor: pointer;
    opacity: 0%;
    width: 192px;
    height: 200px;
    border-radius: 4px;
}

.upload-image-circle {
    width: 125px;
    height: 125px;
    border-radius: 50%;
    background-color: #5764F2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-icon{
    width: 30px;
    height: 30px;
    color: white;
    margin-left: 5px;
}

.thumb-icon {
    width: 30px;
    height: 30px;
    color: white;
    margin-left: 5px;
}

.select-image-header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
}

.close-icon-image-selector {
    color: #73777C;
    height: 30px;
    width: 30px;
    position: absolute;
    right: 15px;
    cursor: pointer;

}

.avatar-cancel-apply {
    display: flex;
    margin-top: 15px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: #2A2D31;
    height: 100%;
    padding: 15px 0px;
    border-radius: 0px 0px 4px 4px;
}

.apply-avatar {
    border: none;
    outline: none;
    background-color:#5764F2;
    color: #F2F3EA;
    width: 60px;
    height: 32px;
    border-radius: 3px;
    font-size: 14px;
    cursor: pointer;
    margin-right: 25px;
}





/* ------------------------------ EDIT FORM SECTION ------------------------------ */

.user-profile-form-container {
    background-color: #1E1F22;
    height: 232px;
    width: 600px;
    border-radius: 0px 0px 4px 4px;
}

.user-profile-form-container-inner {
    padding: 20px;
    padding-top: 0px;
    height: 100%;
}

.user-profile-form {
    padding: 20px;
    background-color: #2A2D31;
    height: 76%;
    border-radius: 8px;
}

.account-user-input-field {
    width: 200px;
    border: none;
    outline: none;
    background-color: #1f2124;
    border-radius: 2px;
    padding: 8px;
    font-size: 15px;
    color: white;
}

.user-profile-form-field {
    display: flex;
    color: white;
    flex-direction: column;
}

.user-profile-display-form {
    display: flex;
    color: white;
    flex-direction: column;
    margin-bottom: 20px;
}

.account-profile-image {
    width: 500px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.add-pfp-icon {
    position: absolute;
    z-index: 20;
    background-color: #E2E5E8;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 28px;
    width: 28px;
    color: #4F545C;
    left: 320px;
    top: 125px;
    box-shadow: -1px 1px 5px #4F545C;
}

.image-edit-icon {
    height: 18px;
    width: 18px;
    margin-left: 2px;
}

/* Separators */

.account-separator {
    border-bottom: solid 1px rgba(72, 72, 72, 0.5);
    margin: 10px 15px;
}
