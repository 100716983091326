.error-page-container {
    height: 100vh;
    width: 100vw;
    background-color: #FEFEFE;
    overflow: hidden scroll;
}

/* ---------------------------- NAV BAR --------------------------------*/
.error-page-logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.125rem;
}

.error-page-logo-wrapper > a {
    text-decoration: none;
    display: flex;
    color: white;
    align-items: center;
    filter: brightness(100) invert(1) sepia(.5) hue-rotate(100deg) saturate(200%);
}

.error-page-logo-wrapper > a > img {
    height: 2.5rem;
    padding-right: 0.625rem;
}

.error-login-button {
    width: 6.25rem;
    height: 2.313rem;
    border-radius: 1.875rem;
    background-color: #5765F2;;
    border-style: none;
    font-size: 0.938;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
}
.error-login-button:hover {
    box-shadow: 0px 0.1rem 0.7rem 0.063rem rgba(0, 0, 0, 0.5);
}


/* -------------------------- ERROR CONTENTS ---------------------------- */

.error-content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px;
}

.error-content {
    width: 670px;
}

.error-title {
    margin-top: 15px;
    font-size: 64px;
    color: #5765F2;
    margin-bottom: 0px;
}

.error-message {
    font-size: 20px;
    line-height: 30px;
}

.error-links-wrapper {
    display: flex;
    flex-direction: column;
    font-size: 20px;
}

.error-links {
    color: #00A8FC;
    width: fit-content;
    margin-bottom: 20px;
}

.error-links:hover {
    text-decoration: underline;
}


.error-image {
    background-image: url("../images//discord-404.gif");
    height: 350px;
    width: 350px;
    background-size: 350px;
}
