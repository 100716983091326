#chat-form-input-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-left: 16px;
    padding-right: 16px;
    grid-row-start: 19;
    grid-row-end: 20;
    margin-bottom: 35px;

}

#chat-input {
    width: 97%;
    padding: 13px 20px 13px 20px;
    font-size: 15px;
    color: #DBDEE1;
    background-color: #373A3F;
    border-style: none;
    border-radius: 8px;
    white-space: normal;
    overflow-wrap: break-word;
    resize: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight:200;
}

#chat-input:focus {
    outline: none;
}




/* icons in the input chat */
.chat-gift-icon,
.chat-gif-icon,
.chat-sticker-icon,
.chat-emoji-icon {
    position: absolute;
    color: #c1c9d2;
    font-size: 25px;
    cursor: pointer;
}

.chat-gift-icon:hover,
.chat-gif-icon:hover,
.chat-sticker-icon:hover {
    color: #edeff1;
}

.chat-emoji-icon:hover {
    color: rgb(235, 201, 53);
}


.chat-gift-icon {
    right: 140px;
}

.chat-gif-icon {
    right: 105px;
}

.chat-sticker-icon {
    right: 70px;
}

.chat-emoji-icon {
    right: 35px;
}
