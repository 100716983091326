.enter-server-name-wrapper {
    display: flex;
    flex-direction: column;
    margin: 5px 15px 15px 15px;
}

.enter-server-name {
    color: #b5bac1;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 5px
}

.enter-server-name-input {
    border: none;
    outline: none;
    padding: 10px;
    border-radius: 3px;
    background-color: #1E1F22;
    color: #f2f3f5;
}

.inner-delete-server {
    background-color: #303338;
    border-radius: 4px;
    box-shadow: 1px 1px 10px#1f2124;
    display: flex;
    width: 440px;
    height: 270px;
    flex-direction: column;
}

.delete-server-cancel-submit {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    background-color: #2A2D31;
    border-radius: 0px 0px 4px 4px;
}

.server-delete-error {
    color: #FA767B;
    margin-top: 5px;
    margin-bottom: 0px;
    font-size: 10px;
}
