.server-nav-container {
    height: 3rem;
    border-bottom: solid 2px rgba(0, 0, 0, .25);
    display: flex;
    align-items: center;
}

.inner-server-nav {
    width: 100%;
    cursor: pointer;
}

.server-nav-container:hover {
    background-color: #404248;
}

.server-nav-title {
    display: flex;
    margin: 16px;
    font-size: 16px;
    color: #F2F3EA;
    justify-content: space-between;
    position: relative;
    align-items: center;
}

#server-nav-active {
    background-color: #404248;
}

.server-name-nav-bar {
    max-width: 180px;
    overflow:hidden;
    white-space:nowrap;
    text-overflow: ellipsis;
    min-width: 0px;
}

/* DROP DOWN */

.server-nav-dropdown {
    position: absolute;
    background-color: #17181a;
    z-index: 1;
    border-radius: 5px;
    top: 55px;
    left: 75px;
    width: 232px;
    height: fit-content;
    color:#F2F3EA;
    padding: 10px 0px;
    cursor: default;
}

.server-dropdown-settings > div {
    display: flex;
    align-items: center;
    margin: 0px 10px;
    height: 32px;
    border-radius: 2px;
    cursor: pointer;
}

.server-dropdown-settings > div > p {
    margin-left: 10px;
    font-size: 14px;
}

.server-dropdown-options:hover {
    background-color: #4752C4;
    color: white;
}

.server-nav-seperator {
    margin: 5px 15px;
    background-color: rgb(67, 67, 67, 0.75);
    height: 1px;
}

/* DROP DOWN LEAVE SERVER */
.server-dropdown-leave{
    display: flex;
    align-items: center;
    margin: 0px 10px;
    height: 32px;
    border-radius: 2px;
    color: #F23F42;
}

.server-dropdown-leave > p {
    margin-left: 10px;
    font-size: 14px;
    width: inherit;
}

.server-dropdown-leave:hover {
    background-color: #F23F42;
    color: white;
}

/* Icons */

.server-nav-icons {
    font-size: 20px;
}
