.server-setting-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.server-inner {
  /* box-shadow: 1px 1px 10px#1f2124; */
  display: flex;
  flex-direction: row;
  background-color: #35393e;
  width: 800px;
  border-radius: 5px;
  margin: 10px;
  height: 400px;
}

.server-inner-2 {
  margin: 0px 20px;
  width: 100%;
  overflow: visible;
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 100%;
}

/* ------------------------ SETTING PAGE ------------------------*/

.server-setting-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  color: white;
  margin-bottom: 0px;
  margin-top: 0px;
  text-align: left;
  width: 100%;
  margin-left: 18px;
}

.server-setting-form {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.server-setting-form-main {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 0px;
  align-items: center;
  flex-direction: column;
  margin-bottom: 12px;
}

.server-setting-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 25px;
}

.server-setting-image {
  background-color: aliceblue;
  height: 125px;
  width: 125px;
  border-radius: 50%;
  margin-bottom: 10px;
  color: rgb(177, 177, 177);
}

.server-setting-image-initials {
  background-color: rgb(48, 48, 48);
  border-radius: 50%;
  width: 125px;
  height: 125px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: rgb(177, 177, 177);
  font-weight: bolder;
  font-size: 40px;
}

.remove-server-image {
  position: absolute;
  top: 210px;
  border: none;
  background-color: transparent;
  color: var(--color-ADADAD);
  cursor: pointer;
  font-weight: bold;
}

.remove-server-image:hover {
  color: var(--color-font-names);
  text-decoration: underline;
}

.remove-server-banner {
  position: absolute;
  top: 210px;
  right: 195px;
  border: none;
  background-color: transparent;
  color: var(--color-ADADAD);
  cursor: pointer;
  font-weight: bold;
}

.remove-server-banner:hover {
  color: var(--color-font-names);
  text-decoration: underline;
}


.upload-server-banner {
  position: absolute;
  top: 210px;
  right: 170px;
  border: none;
  background-color: transparent;
  color: var(--color-ADADAD);
  cursor: pointer;
  font-weight: bold;
}

.server-setting-input-field {
  height: 20px;
  padding: 10px;
  width: 250px;
  font-size: 15px;
  border-radius: 2px;
  border: none;
  background-color: #1f2124;
  color: white;
  outline: none;
}

.server-setting-save-delete-button {
  display: flex;
  align-items: flex-end;
  justify-content: end;
  width: 88%;
}

.server-save-button {
  border: none;
  background-color: #248046;
  color: white;
  padding: 10px 30px;
  border-radius: 3px;
  box-shadow: 1px 1px 5px #1f212486;
  font-size: 14px;
}

.server-save-button:hover {
  background-color: #248046b5;
  cursor: pointer;
}

.server-banner-update {
  width: 100%;
}

.server-banner-default {
  background-color: #4D5057;
  width: 300px;
  height: 92%;
  border-radius: 5px;
  margin-left: 60px;
}

.server-banner-image-update {
  width: 300px;
  height: 124.2px;
  border-radius: 5px;
  margin-left: 60px;
}


/* ------------------------ NAVIGATION -------------------------------- */

.settings-navigation {
  border-radius: 5px 0px 0px 5px;
  width: 30%;
  display: flex;
  justify-items: flex-start;
  flex-direction: column;
  background-color: #2a2d30;
  padding: 20px;
}

.setting-navigation-title {
  color: var(--color-ADADAD);
  margin: 0px;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
  margin-left: 11px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 150px;
}

.setting-navigation-section-name {
  margin: 3px 0px;
  font-size: 18px;
  padding: 5px 12px;
  color: var(--color-ADADAD);
}

.setting-navigation-section-name:hover {
  background-color: #333339;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

.delete-server {
  display: flex;
  align-items: center;
}

.highlight-server-setting {
  background-color: #3f4247;
  border-radius: 5px;
  color: white;
}

.highlight-server-setting:hover {
  background-color: #3f4247;
  border-radius: 5px;
  color: var(--color-ADADAD);
}

/* ------------------------ SEPARATOR ------------------------ */
.setting-separator {
  border-bottom: solid 1px rgba(72, 72, 72, 0.5);
  margin: 10px 0px;
}
