.create-channel-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.inner-create-channel {
    border-radius: 12px;
    box-shadow: 1px 1px 10px#1f2124;
    background-color: var(--color-383A41);
    display: flex;
    flex-direction: column;
    margin: 10px;
}

/* .inner-create-channel-2 {
    padding: 20px;
    width: 100%;
    text-align: center;
    margin-bottom: 8px;
    overflow: visible;
    height: 482px;
    display: flex;
    align-items: center;
    flex-direction: column;
} */

/* CHANNEL HEADING SECTION */

.create-channel-heading {
    display: flex;
    justify-content: space-between;
    padding: 20px 16px 0px 16px
}

.create-channel-section-title {
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: bold;
    color: #B5BAC1
}

.exit-create-channel {
    outline: none;
    border: none;
    background-color: transparent;
}

.exit-create-channel-icon {
    color: #686b6f;
    font-size: 30px;
    cursor: pointer;
}

/*  SELECT CHANNEL TYPE SECTION */

.create-text-channel {
    display: flex;
    align-items: center;
    width: 100%;
}

.create-channel-hash {
    font-size: 24px;
    margin-right: 15px;
    color: #B5BAC1;
}

.channel-radio-header {
    margin: 0px;
    font-size: 16px;
    text-align: left;
    color: #DBDEE1;
}

.radio-circle-channel {
    font-size: 40px;
    color: #DBDEE1;
}

.channel-radio-details {
    margin: 0px;
    font-size: 12px;
    text-align: left;
    margin-top: 5px;
    color: #B5BAC1;
}

.create-text-voice-channel-container {
    padding: 10px 12px;
    display: flex;
    align-items: center;
    background-color: var(--color-313338);
    border-radius: 5px;
    margin-bottom: 8px;
}

.create-text-voice-channel-container:hover {
    background-color: #4f5159;
    cursor: pointer;
}

.create-voice-channel-container:hover {
    background-color: var(--color-313338);
    cursor: default;
}

.create-text-channel-container {
    background-color: #4f5159;
}

/* Input Section */

.create-channel-input-container {
    display: flex;
    padding: 0px 10px;
    border-radius: 3px;
    align-items: center;
    background-color: var(--color-input-field);
}

.new-channel-input {
    border: none;
    background-color: var(--color-input-field);
    outline: none;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border-radius: 2px;
    position: relative;
    color: #DBDEE1;
}

.create-channel-hash-input {
    font-size: 16px;
    color: #B5BAC1;
}

/* submit or cancel create channel form */

.create-channel-cancel-submit {
    background-color: #2b2d31;
    height: 100%;
    padding: 16px;
    border-radius: 0px 0px 12px 12px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.create-channel-cancel {
    border: none;
    background-color: transparent;
    color: white;
    font-size: 14px;
    margin-right: 24px;
    cursor: pointer;
}

.create-channel-cancel:hover {
    text-decoration: underline;
}

.create-channel-submit-active {
    height: 40px;
    background-color: var(--color-submit-button);
    border: none;
    outline: none;
    padding: 0px 18px;
    font-size: 14px;
    color: white;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
}

.create-channel-submit-inactive {
    height: 40px;
    background-color: var(--color-submit-button);
    border: none;
    outline: none;
    padding: 0px 18px;
    font-size: 14px;
    color: var(--color-ADADAD);
    font-weight: bold;
    border-radius: 4px;
    cursor: default;
}
